<template>
  <v-container
    fluid
    class="ao-list"
  >
    <v-card>
      <v-card-title>
        <v-spacer />

        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-database-search"
          label="Search"
          single-line
          hide-details
          clearable
          :disabled="loading"
        />
      </v-card-title>
    </v-card>

    <v-row
      class="led-details d-inlineflex"
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card
          tile
          class="ao-filter-dialog"
        >
          <v-card-text class="elevation-1 white">
            <v-row>
              <v-col cols="12">
                <v-form
                  ref="aoFilterForm"
                  v-model="aoFilterFormValid"
                  lazy-validation
                >
                  <template v-for="field in aoFilterFormData">
                    <FormField
                      :key="field.key"
                      :ref="field.type"
                      :entry="field"
                    />
                  </template>
                </v-form>
              </v-col>

              <v-col
                cols="12"
                class="pt-2"
              >
                <v-btn
                  rounded
                  color="primary"
                  :disabled="loading"
                  @click.stop="setAOFilter"
                >
                  Set
                </v-btn>
                <v-btn
                  rounded
                  color="grey lighten-1"
                  :disabled="loading"
                  @click.stop="clearAOFilter"
                >
                  Clear
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="8"
        xl="9"
      >
        <v-card>
          <v-data-table
            v-model="selected"
            :options.sync="pagination"
            show-select
            :headers="aoListHeaders"
            :items="aoListItems"
            :expanded="expanded"
            :loading="loading"
            item-key="id"
            :items-per-page-items="rowsPerPageOptions"
            class="elevation-1"
            :search="search"
          >
            <template
              #item="{ item, isSelected, select, isExpanded, expand }"
            >
              <tr
                :active="isSelected"
                :class="{'amber lighten-5':item.flag==='warn', 'green lighten-5':item.flag==='ok'}"
                @click="expand(!isExpanded)"
              >
                <td>
                  <v-simple-checkbox
                    :value="isSelected"
                    @input="select($event)"
                  />
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <router-link
                    style="text-decoration: none;"
                    :to="'/experiments/run/'+item.fileid"
                  >
                    {{ item.fileid }}
                  </router-link>
                </td>
                <td>{{ item.datetime.format('[On] ll [at] LT') }}</td>
                <td>{{ item.temperature }} °C</td>
                <td>{{ item.currentshape }}</td>
                <td>
                  {{ item.currentmin }} <span v-if="item.currentsteps > 1"> &ndash; {{ item.currentmax }}</span> <span v-if="item.currentshape === 'dc'"> mA </span><span v-else> V </span> <span v-if="item.currentsteps > 1">({{ item.currentsteps }} steps, {{ item.currenttype }})</span>
                  <span v-if="item.currentshape !== 'dc'">
                    | {{ item.pulsemin }}<span v-if="item.pulsesteps > 1"> &ndash; {{ item.pulsemax }}</span>/s <span v-if="item.pulsesteps > 1">({{ item.pulsesteps }} steps, {{ item.pulsetype }})</span>
                    | {{ item.dutymin }}<span v-if="item.dutysteps > 1"> &ndash; {{ item.dutymax }}</span>% <span v-if="item.dutysteps > 1">({{ item.dutysteps }} steps, {{ item.dutytype }})</span>
                  </span>
                </td>
                <td>{{ item.led }}</td>
              </tr>
            </template>

            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card flat>
                  <v-card-text class="font-weight-bold">
                    {{ item.comment }}

                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <object
                          v-if="item.file1"
                          class="gnuplotSVG"
                          :data="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+item.file1+'&version=svg'+'&apikey='+localStorage.getItem('devAPIKey')"
                        ><img
                          class="gnuplotSVG"
                          :src="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+item.file1+'&version=svg'+'&apikey='+localStorage.getItem('devAPIKey')"
                          type="image/svg+xml"
                        ></object>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <object
                          v-if="item.file2"
                          class="gnuplotSVG"
                          :data="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+item.file2+'&version=svg'+'&apikey='+localStorage.getItem('devAPIKey')"
                        ><img
                          class="gnuplotSVG"
                          :src="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+item.file2+'&version=svg'+'&apikey='+localStorage.getItem('devAPIKey')"
                          type="image/svg+xml"
                        ></object>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="matlabCodeDialog"
    >
      <v-card
        tile
        class="matlab-code-dialog"
      >
        <v-app-bar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="matlabCodeDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Matlab Code</v-toolbar-title>
          <!--<v-spacer/>-->
          <!--<v-toolbar-items>-->
          <!-- If any other shiz is desired in the toolbar, put it here. -->
          <!--</v-toolbar-items>-->
        </v-app-bar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-form
                ref="matlabCodeForm"
                v-model="matlabFormValid"
                lazy-validation
              >
                <template v-for="field in matlabCodeFormData">
                  <FormField
                    :key="field.key"
                    :ref="field.type"
                    :entry="field"
                    @onDynamicUpdate="getMatlabCode"
                  />
                </template>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="aoFilterDialog"
    />

    <v-btn
      dark
      fab
      bottom
      right
      fixed
      class="fab-bottom-right"
      color="accent"
      :disabled="!selected.length"
      @click="getMatlabCode"
    >
      <v-icon>mdi-download</v-icon>
      <span class="fab-text">
        Get MATLAB Code
      </span>
    </v-btn>
  </v-container>
</template>

<script>
import { EventBus } from '../scripts/event_bus'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'ReportList',
  components: {
    FormField: () => import('../components/FormField')
  },
  data () {
    return {
      localLoading: false,
      aoListHeaders: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Experiment ID', value: 'fileid' },
        { text: 'Start of Experiment', value: 'datetime' },
        { text: 'Temperature', value: 'temperature' },
        { text: 'Current type', value: 'currentshape' },
        { text: 'Details', value: 'currentmin' },
        { text: 'LED', value: 'led' }
      ],
      rowsPerPageOptions: [
        10, 20, 30, 50, 100,
        { text: 'All', value: -1 }
      ],
      pagination: {
        sortDesc: [false],
        itemsPerPage: 20,
        sortBy: ['led']
      },
      selected: [],
      expanded: [],
      search: '',
      matlabCodeDialog: false,
      matlabFormValid: true,
      matlabCodeFormData: [],
      aoFilterDialog: false,
      aoFilterFormValid: true,
      aoFilterFormData: []
    }
  },
  computed: {
    ...mapGetters({
      globalLoading: 'loading',
      aoListItems: 'resultList',
      preselectedListItems: 'preselectedResultList'
    }),
    loading: function () {
      return this.globalLoading || this.localLoading
    }
  },
  watch: {
    aoListItems: {
      handler: function (newValue) {
        this.selected = this.preselectedListItems
      },
      deep: true
    }
  },
  mounted () {
    this.refreshAOList()
    this.aoFilterFormData = []
    EventBus.$on('refresh', this.refreshAOList)
    this.openAOFilter()
  },
  beforeDestroy () {
    EventBus.$off('refresh', this.refreshAOList)
    this.clearAOFilter()
  },

  methods: {
    ...mapActions({
      refreshAOList: 'updateResultList'
    }),
    ...mapMutations({
      setAOFilters: 'setResultListFilters'
    }),
    rowClicked: function (_, rowClickEvent) {
      // SIMON: This is the new way to activate by selection.
      // BTW, the data table slot system also completely changed, lel, you'll need to redo it to make pretty things work.
      // I have changed it for all the others that I wrote, but this file is a complete mess, unlike mine, you do it!
      rowClickEvent.select(!rowClickEvent.isSelected)

      this.expanded = !this.expanded
    },
    openAOFilter: function () {
      if (this.aoFilterFormData.length === 0) {
        this.localLoading = true

        this.$root.$data.si.getResultFilterForm(resp => {
          this.aoFilterFormData = resp.fields

          // Because Simon seems to think that the from-to date field should be 1969 to... 1969,
          // I will override this to something useful...
          this.aoFilterFormData.forEach(filterField => {
            if (filterField.key === 'time_start') {
              // Preset this value to something more useful...
              filterField.value = 1517529600
              filterField.date = moment.unix(filterField.value).format('YYYY-MM-DD')
              filterField.time = moment.unix(filterField.value).format('HH:mm')
            } else if (filterField.key === 'time_stop') {
              // Set the time stop to right now.
              filterField.value = moment().unix()
              filterField.date = moment.unix(filterField.value).format('YYYY-MM-DD')
              filterField.time = moment.unix(filterField.value).format('HH:mm')
            }
          })

          console.log(this.aoFilterFormData)

          this.localLoading = false
        })
      } else {
        this.aoFilterDialog = true
      }
    },
    setAOFilter: function () {
      const aoFilters = []

      this.aoFilterFormData.forEach(field => {
        aoFilters.push({ key: field.key, value: field.value })
      })

      this.setAOFilters(aoFilters)

      this.refreshAOList()
    },
    clearAOFilter: function () {
      this.setAOFilters([])
      this.aoFilterFormData = []

      this.aoFilterDialog = false
      this.refreshAOList()
      this.openAOFilter()
    },
    getMatlabCode: function () {
      const aoIds = this.selected.map(ao => ao.id)

      if (!this.loading) {
        this.localLoading = true

        const postArray = [{ key: 'aos', value: aoIds }]

        // Add in any extra fields from the dialog
        this.matlabCodeFormData.forEach(codeFormEntry => {
          postArray.push(codeFormEntry)
        })

        // Clear the array before... assigning to the array?
        // Doesn't work withour this. Why does this work, I have no idea...!?
        this.matlabCodeFormData = []

        this.$root.$data.si.getResultMatlabCode(postArray, response => {
          // console.log(response)

          this.matlabCodeFormData = response.fields

          this.matlabCodeDialog = true
          this.localLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
